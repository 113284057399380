import React from "react"

const ExternalLink = ({ url, children }) => {
  // pass through if no URL
  if (!url) return children

  return (
    <a href={url} target="_blank" rel="noreferrer">
      {children}
    </a>
  )
}

export default ExternalLink
