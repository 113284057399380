import React from "react"

import { SelectFieldSimple } from "../reusable/Form/Fields"

const options = [
  { label: "Distance", value: "distance" },
  { label: "Name", value: "name" },
  { label: "Brand", value: "brand" },
]

const PubFilters = ({ sortOrder, setSortOrder }) => {
  return (
    <div className="sort">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <p className={`uppercase text-sm p-0 m-0`}>Sort by</p>

        <SelectFieldSimple
          id="sortby"
          label="Sort by"
          defaultValue={sortOrder}
          options={options}
          onChange={e => {
            setSortOrder(e.target.value)
          }}
        />
      </div>
    </div>
  )
}

export default PubFilters
