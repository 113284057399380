import React from "react"
// import { createUseStyles } from "react-jss"
import { getBrandLogo } from "./PubPreview"
import { CircleLink } from "./PubPreview"
// import { FaPhoneAlt, FaGlobe } from "react-icons/fa"

const InfoWindow = ({ store }) => {
  // const styles = createUseStyles({
  //   infoWindow: {
  //     // position: "relative",
  //     // backgroundColor: "white",
  //     // padding: "1rem",
  //   },
  // })()

  return (
    <div
      className={`bg-white w-[170px] p-3 flex flex-col gap-2 rounded-md shadow-md`}
    >
      <div>
        <img
          src={getBrandLogo(store?.user_properties?.brand).type}
          alt={`logo`}
          className={`object-contain !max-w-full aspect-square object-center`}
        />
      </div>
      <h6 className="infoWindow__storeName text-blue text-sm font-semibold uppercase">
        {store.name}
      </h6>
      <div className={`flex gap-4 text-gold`}>
        {store?.contact?.phone && (
          <CircleLink url={`tel:${store?.contact?.phone}`}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: 16, height: 16 }}
            >
              <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
            </svg>
          </CircleLink>
        )}
        {store?.user_properties?.websiteUrl && (
          <CircleLink url={store?.user_properties?.websiteUrl}>
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 496 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: 16, height: 16 }}
            >
              <path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
            </svg>
          </CircleLink>
        )}
      </div>
    </div>
  )
}

export default InfoWindow
