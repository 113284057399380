import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from "react"
import ReactDOMServer from "react-dom/server"
import { useForm } from "react-hook-form"

import useScript from "../hooks/useScript"
import { mapConfig, brandOptions, distances } from "./config"
import Button from "../reusable/Button"
import InfoWindow from "./InfoWindow"
import PubList from "./PubList"
import { SelectField } from "../reusable/Form/Fields"
import Autocomplete from "./Autocomplete"

const PubMap = ({ state = null }) => {
  const mapContainerRef = useRef(null)
  const [map, setMap] = useState(null)
  const [mapView, setMapView] = useState(null)
  const [dataSource, setDataSource] = useState(null)
  const woosmapLoaded = useScript(mapConfig.woosmapLoaderUrl)
  const [filters, setFilters] = useState(null)
  const [searchTriggered, setSearchTriggered] = useState(false)
  const [location, setLocation] = useState(state?.coords ? state.coords : null)
  const [locationLabel, setLocationLabel] = useState(null) // eslint-disable-line
  const [stores, setStores] = useState(null)
  const [isMapInit, mapInit] = useReducer(() => true, false)
  const [activeMarker, setActiveMarker] = useState(null)

  const { register, handleSubmit } = useForm()

  const initMap = useCallback(() => {
    mapConfig.woosmapLoadOptions.callback = () => {
      const loader = new window.woosmap.MapsLoader(mapConfig.googleLoadOptions)
      loader.load(() => {
        const map = new window.google.maps.Map(
          mapContainerRef.current,
          mapConfig.googleMapsOptions
        )
        const dataSource = new window.woosmap.DataSource()
        const mapView = new window.woosmap.TiledView(
          map,
          mapConfig.woosmapStyleOptions
        )

        const templateRenderer = {
          render: storeProperties => {
            return ReactDOMServer.renderToString(
              <InfoWindow store={storeProperties} />
            )
          },
        }
        const infoWindow = new window.woosmap.LocatorWindow(
          map,
          templateRenderer,
          mapConfig.infoWindow
        )
        mapView.bindTo("selectedStore", infoWindow)

        setMap(map)
        setMapView(mapView)
        setDataSource(dataSource)
      })
    }

    window.WoosmapLoader.load(mapConfig.woosmapLoadOptions)

    return map
  }, [map])

  useEffect(() => {
    if (woosmapLoaded && !isMapInit) {
      initMap()
    }
    return mapInit
  }, [woosmapLoaded, initMap, isMapInit, mapInit])

  const search = useCallback(
    (location, distance, brand) => {
      if (location) {
        const buildQuery = () => {
          if (brand === "default") {
            return null
          }

          let queryBrand = brand

          if (brand === "High Street") {
            queryBrand = "Oak Tree pubs" // data in Woosmap is inconsistent
          }

          if (brand === "Innkeepers Collection") {
            queryBrand = "Inn Keepers Collection" // data in Woosmap is inconsistent
          }

          return new window.woosmap.query.Query(
            new window.woosmap.query.Field("user.brand", queryBrand, "=")
          )
        }

        const query = buildQuery()

        const searchParams = new window.woosmap.search.SearchParameters({
          lat: location.lat,
          lng: location.lng,
          page: 1,
          storesByPage: mapConfig.storesByPage,
          query: query,
          maxDistanceFromUser: Number(distance * 1609.34),
        })

        dataSource.searchStoresByParameters(searchParams, nearbyStores => {
          mapView.set("stores", nearbyStores.features)
          setStores(nearbyStores)
        })
      } else {
        setStores([])
      }
    },
    [dataSource, mapView]
  )

  // when user clicks "search"
  const onSubmit = data => {
    const query = { ...data, coords: location }

    // on form submit
    setSearchTriggered(true)
    setFilters(query)

    // check if location is not null and distance is set
    if (typeof location !== null && typeof query.distance !== "undefined") {
      search(location, Number(query.distance), query.brand)
    }

    if (location) {
      setMapCenter(location)
    }
  }

  const setMapCenter = useCallback(
    location => {
      if (!location) return
      const lat = location["lat"]
      const lng = location["lng"]

      if (map) {
        map.setCenter({ lat, lng })
        map.setZoom(10)
      }
    },
    [map]
  )

  useEffect(() => {
    if (!activeMarker) return
    dataSource.getStoreById(activeMarker, store => {
      mapView.set("selectedStore", store)
    })
  }, [activeMarker])

  // if state passed
  useEffect(() => {
    if (state?.coords && woosmapLoaded && isMapInit) {
      setMapCenter(state.coords)
      const distance = state.distance ? state.distance : 10
      search(state.coords, distance, state.brand)
      setSearchTriggered(true)
    }
  }, [state, setMapCenter])

  let defaultBrand = "default"
  if (state && state.brand) {
    for (let i = 0; i < brandOptions.length; i++) {
      if (
        brandOptions[i].value === state.brand ||
        brandOptions[i].value.toLowerCase() === state.brand
      ) {
        defaultBrand = brandOptions[i].value
        break
      }
    }
  }

  return (
    <>
      <div>
        <div>
          <div
            className={`relative z-50 w-full max-w-[1110px] mx-auto lg:absolute lg:right-0 lg:left-0 lg:pt-6`}
          >
            <form
              className={`relative flex items-center justify-between gap-4 p-4 rounded-none z-10 bg-blue md:p-8 md:gap-6 flex-row flex-wrap`}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* <h5>Find a restaurant or pub</h5> */}
              <Autocomplete
                map={map}
                register={register}
                locationHooks={location}
                setLocationLabel={setLocationLabel}
                setLocationHooks={setLocation}
                defaultValue={state ? state.location : null}
              />

              <SelectField
                id="brand"
                label="All Brands"
                disablePlaceholder={false}
                defaultValue={defaultBrand}
                options={brandOptions}
                register={register}
              />
              <SelectField
                id="distance"
                label="Distance"
                defaultValue={10}
                options={distances}
                register={register}
              />

              <div className="">
                <Button
                  text="Search"
                  buttonStyle="primary"
                  colour="orange"
                  button={true}
                  type="submit"
                  disabled={!location}
                />
              </div>
            </form>
          </div>
          <div
            id="mapWrapper"
            className={`relative min-h-[480px] max-w-full mt-8 aspect-[1.5] pt-px rounded-none overflow-hidden md:aspect-[2] md:mt-0`}
          >
            <div
              className="mapContainer"
              style={{
                position: "absolute",
                inset: "0 0 0 0",
              }}
              ref={mapContainerRef}
            />
          </div>
        </div>
      </div>
      <PubList
        data={stores}
        filters={filters}
        searchTriggered={searchTriggered}
        feedbackStyle={null}
        setActiveMarker={setActiveMarker}
      />
    </>
  )
}

export default PubMap
