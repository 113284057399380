import React from "react"
import PropTypes from "prop-types"
import { FaPhoneAlt, FaGlobe, FaMapMarkerAlt } from "react-icons/fa"

// import { Flex } from "../reusable/common"
import Button from "../reusable/Button"
import ExternalLink from "../reusable/ExternalLink"
import { brandLogos } from "./config"
import { Fallback as FallbackLogo } from "../../images/brand-logos/logos"

const milesToMetres = (value, toMetres = true) => {
  if (!value) return

  if (!toMetres) return Number(value) * 1609.344

  return (Number(value) / 1609.344).toFixed(2)
}

export const getBrandLogo = brand => {
  if (!brand) return <FallbackLogo />

  // find closest match for brand in brandLogos array
  const closestMatch = brandLogos.find(brandLogo => {
    const brandLogoName = brandLogo.name.toLowerCase().replace(/'/g, "")
    const brandName = brand.toLowerCase().replace(/'/g, "")

    if (brandLogoName.includes(brandName)) {
      return brandLogo
    } else {
      return null
    }
  })
  return closestMatch ? closestMatch?.logo : <FallbackLogo />
}

const PubPreview = ({
  id,
  name,
  brand,
  address,
  phoneNumber,
  lat,
  lng,
  distance,
  websiteUrl,
  contactUsUrl,
  feedbackStyle = false,
  index,
  setActiveMarker,
}) => {
  // const brandLogo = null
  const brandLogo = getBrandLogo(brand)

  const pinValue = "../../map-pin-white.svg"

  return (
    <div
      id={id}
      className={`flex flex-wrap rounded overflow-hidden shadow-lg mb-6 justify-end bg-white lg:relative`}
    >
      <button
        className="pin flex-[0_0_90px] flex items-center justify-center relative md:mb-[-114px] lg:mb-0 2xl:flex-[0_0_138px] 2xl:max-w-[138px]"
        style={{
          cursor: "pointer",
        }}
        onKeyDown={() => {
          // TODO: set map location to current locationDetails
          const map = document.getElementById("mapWrapper")
          map.scrollIntoView({
            behavior: "smooth",
          })
          setActiveMarker(id)
        }}
        onClick={() => {
          // TODO: set map location to current locationDetails
          const map = document.getElementById("mapWrapper")
          map.scrollIntoView({
            behavior: "smooth",
          })
          setActiveMarker(id)
        }}
      >
        <span
          className={`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-base font-bold text-white pb-3`}
        >
          {index + 1}
        </span>
        <img src={pinValue} alt={`Map pin`} className={`w-[52px] h-[64px]`} />
      </button>
      {brandLogo && (
        <div className="logo flex-[0_0_calc(100%_-_90px)] flex items-center justify-center p-3 md:flex-[0_0_200px] md:max-w-[200px] lg:flex-[0_0_150px] lg:max-w-[150px]">
          <img
            src={brandLogo.type}
            alt={`${brand} logo`}
            className={`w-[140px]`}
          />
        </div>
      )}
      <div className="address text-blue p-6 flex-[0_0_100%] max-w-[100%] md:flex-[0_0_calc(100%_-_290px)] md:max-w-[calc(100%_-_290px)] lg:flex-[0_0_calc(100%_-_520px)] lg:max-w-[calc(100%_-_520px)] lg:mr-[280px] 2xl:py-10 2xl:flex-[0_0_calc(100%_-_570px)] 2xl:max-w-[calc(100%_-_570px)]">
        {distance > 0 && (
          <p className="distance flex items-center justify-start text-lg leading-[24px] tracking-[0,016em] mb-3">
            <i className={`w-6 h-6 mr-3`}>
              <FaMapMarkerAlt />
            </i>
            <span>{milesToMetres(distance)} Miles</span>
          </p>
        )}
        <h6
          className={`mb-3 tracking-[0.016em] text-[24px] leading-[32px] underline`}
        >
          <ExternalLink url={websiteUrl} className={``}>
            {name}
          </ExternalLink>
        </h6>
        <p className={`text-base tracking-[0.016em] leading-normal mb-0`}>
          {address}
        </p>
      </div>
      {!feedbackStyle && contactUsUrl && (
        <div className="feedback-link px-6 text-center flex-[0_0_100%] max-w-full flex items-center justify-center md:flex-[0_0_280px] md:w-[280px] md:p-6 lg:absolute lg:top-0 lg:right-0 lg:bottom-1/2 lg:items-end lg:pb-0">
          <a
            href={contactUsUrl}
            target="_blank"
            rel="noreferrer"
            className={`inline-block px-6 py-3 text-white bg-gold text-lg hover:bg-gold-dark transition-transform duration-300 transform uppercase font-thin !no-underline`}
          >
            Guest Feedback
          </a>
        </div>
      )}
      <div className="actions text-gold flex flex-[0_0_100%] max-w-full items-center justify-around p-6 md:flex-[calc(100%_-_370px)] md:max-w-[calc(100%_-_370px)] lg:pt-0 lg:flex-[0_0_280px] lg:w-[280px] lg:absolute lg:bottom-0 lg:right-0 lg:top-1/2 lg:items-start lg:[&_a]:h-[52px]">
        {phoneNumber && (
          <CircleLink url={`tel:${phoneNumber}`}>
            <FaPhoneAlt />
          </CircleLink>
        )}
        {websiteUrl && (
          <CircleLink url={websiteUrl}>
            <FaGlobe />
          </CircleLink>
        )}
      </div>
      {feedbackStyle && contactUsUrl && (
        <div className="button-wrap">
          <Button
            text="Leave Feedback"
            link={contactUsUrl}
            colour="orange"
            buttonStyle="primary"
          />
        </div>
      )}
    </div>
  )
}

export const CircleLink = ({ url, children }) => {
  return (
    <a
      href={url}
      className={`flex-1 p-3 flex items-center justify-center`}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

// types in lieu of TypeScript
PubPreview.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  brand: PropTypes.string,
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
  distance: PropTypes.number,
  lat: PropTypes.number,
  lng: PropTypes.number,
  websiteUrl: PropTypes.string,
  contactUsUrl: PropTypes.string,
}

export default PubPreview
