import React, { useState } from "react"
import PubFilters from "./PubFilters"
import PubPreview from "./PubPreview"

const PubList = ({ data, searchTriggered, feedbackStyle, setActiveMarker }) => {
  const [sortOrder, setSortOrder] = useState("distance")

  const filteredPubs = () => {
    if (sortOrder === "distance") {
      return data?.features?.sort(
        (a, b) => a.properties.distance - b.properties.distance
      )
    }
    if (sortOrder === "name") {
      return data?.features?.sort((a, b) =>
        a.properties.name
          .toLowerCase()
          .localeCompare(b.properties.name.toLowerCase())
      )
    }
    if (sortOrder === "brand") {
      // sort and group filteredPubs by brand, skip if no brand
      return data?.features?.sort((a, b) => {
        if (
          a.properties.user_properties.brand &&
          b.properties.user_properties.brand
        ) {
          return a.properties.user_properties.brand
            .toLowerCase()
            .localeCompare(b.properties.user_properties.brand.toLowerCase())
        }
        return 0
      })
    }

    return data?.features
  }

  // hide el if none user searchTriggered
  if (!searchTriggered || !data) return null

  return (
    <div
      style={{
        position: "relative",
        zIndex: 10,
      }}
    >
      <div className="bg-blue">
        {filteredPubs() && filteredPubs().length > 0 ? (
          <div className="pub-list-wrap mx-auto max-w-[1110px] pt-10 px-4">
            <div className="md:flex md:justify-between md:gap-6 mb-4 md:mb-10">
              <h2 className={`mt-0`}>{`${
                filteredPubs() ? filteredPubs().length + " " : ""
              }Mitchells & Butlers restaurants and pubs`}</h2>
              <PubFilters sortOrder={sortOrder} setSortOrder={setSortOrder} />
            </div>
            <div className="pub-results">
              {filteredPubs().length > 0 &&
                filteredPubs().map((item, index) => {
                  const pub = {
                    id: item?.properties?.store_id || index,
                    name: item?.properties?.name,
                    brand: item?.properties?.user_properties?.brand,
                    address: processAddress(item?.properties?.address),
                    phoneNumber: item?.properties?.contact?.phone,
                    distance: item?.properties?.distance,
                    lat: item?.geometry?.coordinates[1],
                    lng: item?.geometry?.coordinates[0],
                    tradingStatus:
                      item?.properties?.user_properties?.tradingStatus,
                    tags: item?.properties?.tags,
                    websiteUrl: item?.properties?.user_properties?.websiteUrl,
                    contactUsUrl:
                      item?.properties?.user_properties?.contactUsUrl,
                  }

                  return (
                    <PubPreview
                      key={pub.id}
                      {...pub}
                      index={index}
                      feedbackStyle={feedbackStyle}
                      setActiveMarker={setActiveMarker}
                    />
                  )
                })}
            </div>
          </div>
        ) : (
          <div className="pub-list-wrap mx-auto max-w-[1110px] px-4">
            <div style={{ marginBottom: "1.5rem" }}>
              <h2>We were unable to find any matches for your search.</h2>
              <p style={{ marginTop: "1rem" }}>
                You may want to do one of the following to refine your search.
              </p>
              <ul>
                <li>Check the spelling of your search and try again</li>
                <li>
                  Try searching with a full UK postcode instead of location
                </li>
                <li>
                  Use the map to find the area you are interested in and select
                  a marker
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PubList

function processAddress(address) {
  if (typeof address === "string") return address
  const { lines, zipcode, city, country_code } = address
  // remove empty lines
  const filteredLines = lines.filter(line => line !== "")
  return `${filteredLines.join(", ")}, ${city}, ${zipcode}, ${country_code}`
}
