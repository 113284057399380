import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PubMap from "../components/PubMap/PubMap"
import PubList from "../components/PubMap/PubList"

const NationalSearch = props => {
  return (
    <Layout>
      <Seo
        title={`Where can I use my Dining Out Gift Card | Find pubs & Restaurants`}
        description={`Browse the list of Dining Out Card restaurants, pubs & bars across the UK and discover your new favourite. Mitchells & Butlers pubs and restaurants near me.`}
      />

      <div className="content-wrapper">
        <div className="main">
          <h1>Discover where to dine and drink</h1>

          <p>
            Looking for a buzzing brasserie for after-work dinner and drinks? Or
            a comfy pub serving traditional Sunday roasts? At Dining Out, you
            can browse more than{" "}
            <strong>1,500 places to eat, drink and sleep across the UK</strong>.
            Just search below to find the right one for you.
          </p>
        </div>
      </div>

      <div className="w-full">
        <PubMap state={props?.location?.state} />
      </div>

      <div className="mx-auto max-w-[1110px]">
        <PubList />
      </div>
    </Layout>
  )
}

export default NationalSearch
